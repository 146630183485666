.title {
  text-align: center;
  padding: 20px 10px 0px;
  font-size: 32px;
  font-weight: 700;
  background-color: var(--background-color, #ffffff);
  margin: 0;
}

.league-info {
  text-align: center;
  padding: 0px 10px 15px;
  font-size: 18px;
  color: #333;
  background-color: var(--background-color, #ffffff);
  margin: 0;
}

.league-info span {
  margin: 0 5px;
}

.separator {
  margin: 0 5px;
  color: #555;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 10px 20px;
  max-width: 100%;
  margin: 0 auto;
}

.player-card {
  position: relative;
  width: calc(50% - 30px);
  min-width: 200px;
  max-width: 400px;
  margin: 15px;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  aspect-ratio: 16/10;
}

.player-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
}

.player-image {
  width: 100%;
  height: auto;
  display: block;
}

.player-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.9));
  padding: 20px;
  color: #fff;
}

.player-name {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.player-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.team-info {
  display: flex;
  align-items: center;
}

.team-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.team-name {
  font-size: 18px;
  font-weight: 700;
}

.position {
  font-size: 18px;
  background-color: #ff6f61;
  padding: 5px 10px;
  border-radius: 5px;
}

.opponent {
  font-size: 16px;
  margin-top: 5px;
}

.selected {
  border: 5px solid #4caf50;
  box-shadow: 0 0 20px rgba(76, 175, 80, 0.5);
}

.not-selected {
  opacity: 0.6;
  filter: grayscale(100%);
}

.percentage {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .player-card {
    width: 100%;
    min-width: unset;
    margin: 10px 0;
  }

  .title {
    font-size: 24px;
    padding: 15px 10px 0px;
  }

  .results-message {
    font-size: 1rem;
  }
}

.start-sit-content {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.results-container {
  text-align: center;
  margin-top: 2rem;
}

.results-message {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.browse-button {
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.browse-button:hover {
  background-color: #45a049;
}
